import React, { useEffect, useState } from "react"
import { useForm, ValidationError } from "@formspree/react"
import StyledDropzone from "../Inputs/StyledDropzone"
import { singleSigns } from "../SSOIntegrations/data"
import { hrPlatForms } from "../HRISIntegrations/data"
import axios from "axios"
import config from "../../../config"

const IntegrationChecklist = ({ setSucceeded }: { setSucceeded: Function }) => {
  // get the "t" from the url search params
  const urlParams =
    typeof window !== "undefined"
      ? new URLSearchParams(window.location.search)
      : undefined
  const t = urlParams?.get("t")
  const parsedToken = t ? JSON.parse(atob(t)) : null
  const defaultEmail = parsedToken ? parsedToken.email : ""
  const defaultOrganization = parsedToken ? parsedToken.org : ""
  const defaultName = parsedToken ? parsedToken.name : ""
  const defaultWebsite = parsedToken ? parsedToken.site : ""
  const checklistTaskId = parsedToken ? parsedToken.checklistTaskId : ""

  const [hasCompanyWebsite, setHasCompanyWebsite] = useState(!!defaultWebsite)
  const [hasEmployeePortal, setHasEmployeePortal] = useState(false)
  const [hasSSO, setHasSSO] = useState(false)
  const [hasSSOAccess, setHasSSOAccess] = useState(true)
  const [hasHRIS, setHasHRIS] = useState(false)
  const [useCustomSender, setUseCustomSender] = useState(false)
  const [notifyTeam, setNotifyTeam] = useState(false)
  const [submissionError, setSubmissionError] = useState("")
  const [uploadedFiles, setUploadedFiles] = useState<
    { name: string; file: File }[]
  >([])
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [state] = useForm("myyrnkao")
  const [teamMembers, setTeamMembers] = useState<
    { name: string; email: string }[]
  >([{ name: "", email: "" }])

  useEffect(() => {
    if (state.succeeded) {
      setSucceeded(true)
    }
  }, [state.succeeded, setSucceeded])

  const submitForm = async (form: React.FormEvent<HTMLFormElement>) => {
    form.preventDefault()
    console.log("submitting form")
    setSubmissionError("")
    const formData = new FormData()
    const formElements = form.currentTarget.elements
    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i] as HTMLInputElement
      if (element.name) {
        if (element.name === "teamFullName") {
          // get the team email by id, it should have the same number as the teamFullName{num}
          const teamEmail = formElements.namedItem(
            `teamEmail${element.id.split("teamFullName")[1]}`
          ) as HTMLInputElement
          formData.append(
            "teamMembers",
            JSON.stringify({ name: element.value, email: teamEmail.value })
          )
        } else if (element.name === "teamEmail") {
          // ignore team email inputs
        } else if (element.type === "file") {
          // ignore file inputs
        } else if (element.type === "radio") {
          if (element.checked) {
            formData.append(
              element.name,
              element.value === "yes" ? "true" : "false"
            )
          }
        } else if (element.type === "checkbox") {
          if (element.checked) {
            const ssoNum = selectedOptions.filter(option =>
              singleSigns.map(sso => sso.name).includes(option)
            )
            const hrisNum = selectedOptions.filter(option =>
              hrPlatForms.map(hris => hris.name).includes(option)
            )

            if (
              (element.name === "ssoPlatform" && ssoNum.length === 1) ||
              (element.name === "hrisPlatform" && hrisNum.length === 1)
            ) {
              formData.append(element.name, JSON.stringify([element.value]))
            } else formData.append(element.name, element.value)
          }
        } else if (element.value) {
          formData.append(element.name, element.value)
        }
      }
    }

    if (uploadedFiles.length > 10) {
      setSubmissionError("You can only upload a maximum of 10 files")
      return
    }

    uploadedFiles.forEach(({ name, file }) => {
      formData.append(name, file)
    })

    if (checklistTaskId != null && checklistTaskId != "") {
      formData.append("checklistTaskId", checklistTaskId)
    }

    try {
      const baseURL = config.NEW_API_HOST
      if (!baseURL) return
      const response = await axios.post(`${baseURL}/form/integration`, formData)
      if (response.status === 200) {
        setSucceeded(true)
      }
      console.log(response.data)
    } catch (error) {
      console.error(error)
      const errorText =
        error.response && error.response.data && error.response.data.error
      if (errorText === "File too large") {
        setSubmissionError(
          "One of the files you uploaded is too large. Please make sure all files are under 10MB."
        )
      } else {
        setSubmissionError(
          "There was an error submitting the form. Please try again later."
        )
      }
    }
  }

  const handleOptionChange = (option: any) => {
    setSelectedOptions((prevOptions: any) => {
      if (prevOptions.includes(option)) {
        return prevOptions.filter((o: any) => o !== option)
      } else {
        return [...prevOptions, option]
      }
    })
  }

  return (
    <div className="policies-table">
      <form
        className="client-form rounded-13px shadow-md border border-gray-3 px-12 py-10 mx-auto md:ml-175px max-w-lg sm:translate-y-0 bg-white"
        onSubmit={submitForm}
        encType={"multipart/form-data"}
      >
        {/*  Company name */}
        <h4 className="text-24px mb-4 font-normal">*Organization name:</h4>
        <input
          name="companyName"
          placeholder="Your company name"
          defaultValue={defaultOrganization}
          required
          type="text"
          className="border border-gray-10 rounded-md shadow-sm bg-white text-gray-1 w-full text-15px py-10px px-15px mb-8"
        />
        <ValidationError
          prefix="Company name"
          field="companyName"
          errors={state.errors}
        />{" "}
        {/* TODO: add these everywhere */}
        {/* Your Details */}
        <h4 className="text-24px mb-4 font-normal">*Your information:</h4>
        <div className="flex">
          <input
            name="fullName"
            placeholder="Full name"
            defaultValue={defaultName}
            required
            type="text"
            className="border border-gray-10 rounded-md shadow-sm bg-white text-gray-1 w-full text-15px py-10px px-15px mb-8 mr-3"
          />
          <ValidationError
            prefix="Full name"
            field="fullName"
            errors={state.errors}
          />
          <input
            name="email"
            placeholder="Email address"
            defaultValue={defaultEmail}
            required
            type="email"
            className="border border-gray-10 rounded-md shadow-sm bg-white text-gray-1 w-full text-15px py-10px px-15px mb-8 ml-3"
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>
        {/*  Company Website */}
        <h4 className="text-24px mb-4 font-normal">
          *Do you have a company website?
        </h4>
        <div className="flex mb-8">
          <div className="flex items-center mr-8">
            <input
              id="inline-radio-companysite"
              type="radio"
              value="yes"
              name="hasCompanyWebsite"
              checked={hasCompanyWebsite}
              onClick={() => setHasCompanyWebsite(true)}
              className="w-5 h-5 text-gray-1 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor="inline-radio-companysite"
              className={`ml-2 text-14px font-medium text-gray-900 ${
                hasCompanyWebsite ? "font-bold" : ""
              }`}
            >
              Yes
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="inline-2-radio-companysite"
              type="radio"
              value="no"
              name="hasCompanyWebsite"
              checked={!hasCompanyWebsite}
              onClick={() => setHasCompanyWebsite(false)}
              className="w-5 h-5 text-gray-1 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor="inline-2-radio-companysite"
              className={`ml-2 text-14px font-medium text-gray-900 ${
                !hasCompanyWebsite ? "font-bold" : ""
              }`}
            >
              No
            </label>
          </div>
        </div>
        {/*  Employee Portal */}
        <div className={`${hasCompanyWebsite ? "" : "hidden"}`}>
          <h4 className="text-24px mb-4 font-normal">
            *Do you want to customize your employee portal?
          </h4>
          <h5 className="text-14px mb-4 italic font-normal leading-17px">
            By default, your employee portal URL is
            books.airmason.com/companyname. If you would like to upgrade your
            employee portal URL to match your company domain such as
            books.companywebsite.com, resources.companywebsite.com,
            customizehere.companywebsite.com
          </h5>
          <div className="flex mb-8">
            <div className="flex items-center mr-8">
              <input
                id="inline-radio-employeeportal"
                type="radio"
                value="yes"
                name="hasEmployeePortal"
                checked={hasEmployeePortal}
                onClick={() => setHasEmployeePortal(true)}
                className="w-5 h-5 text-gray-1 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
              />
              <label
                htmlFor="inline-radio-employeeportal"
                className={`ml-2 text-14px font-medium text-gray-900 ${
                  hasEmployeePortal ? "font-bold" : ""
                }`}
              >
                Yes
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="inline-2-radio-employeeportal"
                type="radio"
                value="no"
                name="hasEmployeePortal"
                checked={!hasEmployeePortal}
                onClick={() => setHasEmployeePortal(false)}
                className="w-5 h-5 text-gray-1 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
              />
              <label
                htmlFor="inline-2-radio-employeeportal"
                className={`ml-2 text-14px font-medium text-gray-900 ${
                  !hasEmployeePortal ? "font-bold" : ""
                }`}
              >
                No
              </label>
            </div>
          </div>
        </div>
        {/*  Employee Portal Input */}
        <div
          className={`${
            hasCompanyWebsite && hasEmployeePortal ? "" : "hidden"
          }`}
        >
          <h4 className="text-24px mb-4 font-normal">
            *Please provide us with the custom employee portal.
          </h4>
          <input
            name="employeePortalInput"
            placeholder="custom.airmason.com"
            required={hasCompanyWebsite && hasEmployeePortal}
            type="text"
            className="border border-gray-10 rounded-md shadow-sm bg-white text-gray-1 w-full text-15px py-10px px-15px mb-8"
          />
        </div>
        {/*  SSO? */}
        <h4 className="text-24px mb-4 font-normal">
          *Does your team use a Single Sign On (SSO) application?
        </h4>
        <div className="flex mb-8">
          <div className="flex items-center mr-8">
            <input
              id="inline-radio-sso"
              type="radio"
              value="yes"
              name="hasSSO"
              checked={hasSSO}
              onClick={() => setHasSSO(true)}
              className="w-5 h-5 text-gray-1 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor="inline-radio-sso"
              className={`ml-2 text-14px font-medium text-gray-900 ${
                hasSSO ? "font-bold" : ""
              }`}
            >
              Yes
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="inline-2-radio-sso"
              type="radio"
              value="no"
              name="hasSSO"
              checked={!hasSSO}
              onClick={() => setHasSSO(false)}
              className="w-5 h-5 text-gray-1 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor="inline-2-radio-sso"
              className={`ml-2 text-14px font-medium text-gray-900 ${
                !hasSSO ? "font-bold" : ""
              }`}
            >
              No
            </label>
          </div>
        </div>
        {/*  SSO Platforms */}
        <div className={`flex-col w-full ${!hasSSO ? "hidden" : "flex"}`}>
          <div className="relative border border-gray-10 rounded-13px shadow-sm bg-white mb-8 h-48 overflow-y-auto py-4 px-2">
            {singleSigns.map((option, key) => (
              <div key={key} className="flex justify-between py-2 px-4">
                <label className="flex items-center space-x-3 flex-1">
                  <input
                    type="checkbox"
                    name="ssoPlatform"
                    value={option.name}
                    checked={selectedOptions.includes(option.name)}
                    onChange={() => handleOptionChange(option.name)}
                    className="form-checkbox h-5 w-5 text-gray-600"
                  />
                  <span className="text-gray-700 mx-4">
                    {option.title || option.name}
                  </span>
                </label>
                <div className="flex-1 flex">
                  <img
                    src={option.icon}
                    alt={option.name}
                    className="h-6 w-auto"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {/*  Custom SSO */}
        <div className={`${hasSSO ? "" : "hidden"}`}>
          <h4 className="text-24px mb-4 font-normal">
            If your SSO platform is not on the list above, please specify:
          </h4>
          <input
            name="customSSO"
            placeholder="My custom SSO"
            type="text"
            className="border border-gray-10 rounded-md shadow-sm bg-white text-gray-1 w-full text-15px py-10px px-15px mb-8"
          />
        </div>
        {/*  SSO Access? */}
        <div className={`${hasSSO ? "" : "hidden"}`}>
          <h4 className="text-24px mb-4 font-normal">
            *Does every employee have access to sign in your SSO?
          </h4>
          <div className="flex mb-8">
            <div className="flex items-center mr-8">
              <input
                id="inline-radio-ssoaccess"
                type="radio"
                value="yes"
                name="hasSSOAccess"
                checked={hasSSOAccess}
                onClick={() => setHasSSOAccess(true)}
                className="w-5 h-5 text-gray-1 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
              />
              <label
                htmlFor="inline-radio-ssoaccess"
                className={`ml-2 text-14px font-medium text-gray-900 ${
                  hasSSOAccess ? "font-bold" : ""
                }`}
              >
                Yes
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="inline-2-radio-ssoaccess"
                type="radio"
                value="no"
                name="hasSSOAccess"
                checked={!hasSSOAccess}
                onClick={() => setHasSSOAccess(false)}
                className="w-5 h-5 text-gray-1 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
              />
              <label
                htmlFor="inline-2-radio-ssoaccess"
                className={`ml-2 text-14px font-medium text-gray-900 ${
                  !hasSSOAccess ? "font-bold" : ""
                }`}
              >
                No
              </label>
            </div>
          </div>
        </div>
        {/*  HRIS? */}
        <h4 className="text-24px mb-4 font-normal">
          *Do you use a Human Resource Information System (HRIS)?
        </h4>
        <div className="flex mb-8">
          <div className="flex items-center mr-8">
            <input
              id="inline-radio-hris"
              type="radio"
              value="yes"
              name="hasHRIS"
              checked={hasHRIS}
              onClick={() => setHasHRIS(true)}
              className="w-5 h-5 text-gray-1 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor="inline-radio-hris"
              className={`ml-2 text-14px font-medium text-gray-900 ${
                hasHRIS ? "font-bold" : ""
              }`}
            >
              Yes
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="inline-2-radio-hris"
              type="radio"
              value="no"
              name="hasHRIS"
              checked={!hasHRIS}
              onClick={() => setHasHRIS(false)}
              className="w-5 h-5 text-gray-1 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor="inline-2-radio-hris"
              className={`ml-2 text-14px font-medium text-gray-900 ${
                !hasHRIS ? "font-bold" : ""
              }`}
            >
              No
            </label>
          </div>
        </div>
        {/*  HRIS Platforms */}
        <div className={`flex-col w-full ${!hasHRIS ? "hidden" : "flex"}`}>
          <div className="relative border border-gray-10 rounded-13px shadow-sm bg-white mb-8 h-48 overflow-y-auto py-4 px-2">
            {hrPlatForms.map((option, key) => (
              <div key={key} className="flex justify-between py-2 px-4">
                <label className="flex items-center space-x-3 flex-1">
                  <input
                    type="checkbox"
                    name="hrisPlatform"
                    value={option.name}
                    checked={selectedOptions.includes(option.name)}
                    onChange={() => handleOptionChange(option.name)}
                    className="form-checkbox h-5 w-5 text-gray-600"
                  />
                  <span className="text-gray-700 mx-4">
                    {option.title || option.name}
                  </span>
                </label>
                <div className="flex-1 flex">
                  <img
                    src={option.icon}
                    alt={option.name}
                    className="h-6 w-auto"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {/*  Custom HRIS */}
        <div className={`${hasHRIS ? "" : "hidden"}`}>
          <h4 className="text-24px mb-4 font-normal">
            If your HRIS platform is not on the list above, please specify:
          </h4>
          <input
            name="customHRIS"
            placeholder="My custom HRIS"
            type="text"
            className="border border-gray-10 rounded-md shadow-sm bg-white text-gray-1 w-full text-15px py-10px px-15px mb-8"
          />
        </div>
        {/*  Custom Email Sender? */}
        <h4 className="text-24px mb-4 font-normal">
          *Would you like to use a custom sender for sending communications to
          your employees from the AirMason platform?
        </h4>
        <div className="flex mb-8">
          <div className="flex items-center mr-8">
            <input
              id="inline-radio-customsender"
              type="radio"
              value="yes"
              name="useCustomSender"
              checked={useCustomSender}
              onClick={() => setUseCustomSender(true)}
              className="w-5 h-5 text-gray-1 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor="inline-radio-customsender"
              className={`ml-2 text-14px font-medium text-gray-900 ${
                useCustomSender ? "font-bold" : ""
              }`}
            >
              Yes
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="inline-2-radio-customsender"
              type="radio"
              value="no"
              name="useCustomSender"
              checked={!useCustomSender}
              onClick={() => setUseCustomSender(false)}
              className="w-5 h-5 text-gray-1 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor="inline-2-radio-customsender"
              className={`ml-2 text-14px font-medium text-gray-900 ${
                !useCustomSender ? "font-bold" : ""
              }`}
            >
              No
            </label>
          </div>
        </div>
        {/*  Custom Email Sender Input */}
        <div className={`${useCustomSender ? "" : "hidden"}`}>
          <h4 className="text-24px mb-4 font-normal">
            What email address would you like to designate for AirMason
            communications to your employees?*
          </h4>
          <h5 className="text-14px mb-4 italic font-normal leading-17px">
            It must be an active and valid email address.
          </h5>
          <h5 className="text-14px mb-4 italic font-normal leading-17px">
            For example: info@yourorganization.com, hrteam@yourorganization.com.
          </h5>
          <input
            name="customEmailSenderInput"
            placeholder="custom@airmason.com"
            required={useCustomSender}
            type="text"
            className="border border-gray-10 rounded-md shadow-sm bg-white text-gray-1 w-full text-15px py-10px px-15px mb-8"
          />
        </div>
        <h4 className="text-24px mb-4 font-normal">
          If you would like to customize your email header banner to a
          designated company one, please drop it below:
        </h4>
        <h5 className="text-14px mb-4 italic font-normal leading-17px">
          *Otherwise, please send it at your convenience to
          integrations@airmason.com
        </h5>
        <StyledDropzone
          className="mb-8"
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          text={`<span className="underline text-blue-link">
              Select / drop your header file(s) here
            </span>`}
          isFileRequiredAndMissing={false}
          inputName="headerImage"
        />
        {/* Notify Team? */}
        <h4 className="text-24px mb-4 font-normal">
          Is there anyone on your team that you will want us to include in the
          integration process:
        </h4>
        <div className="flex mb-8">
          <div className="flex items-center mr-8">
            <input
              id="inline-radio-notifyTeam"
              type="radio"
              value="yes"
              name="notifyTeam"
              checked={notifyTeam}
              onClick={() => setNotifyTeam(true)}
              className="w-5 h-5 text-gray-1 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor="inline-radio-notifyTeam"
              className={`ml-2 text-14px font-medium text-gray-900 ${
                notifyTeam ? "font-bold" : ""
              }`}
            >
              Yes
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="inline-2-radio-notifyTeam"
              type="radio"
              value="no"
              name="notifyTeam"
              checked={!notifyTeam}
              onClick={() => setNotifyTeam(false)}
              className="w-5 h-5 text-gray-1 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor="inline-2-radio-notifyTeam"
              className={`ml-2 text-14px font-medium text-gray-900 ${
                !notifyTeam ? "font-bold" : ""
              }`}
            >
              No
            </label>
          </div>
        </div>
        {/* Their Details */}
        <div className={`${notifyTeam ? "" : "hidden"}`}>
          <h4 className="text-24px mb-4 font-normal">Enter their details</h4>
          {teamMembers.map((teamMember, index) => (
            <div className="flex items-baseline" key={index}>
              <input
                name={`teamFullName${index}`}
                placeholder="Full name"
                type="text"
                required={notifyTeam}
                id={index.toString()}
                value={teamMember.name}
                onChange={e => {
                  const newTeamMembers = [...teamMembers]
                  newTeamMembers[index].name = e.target.value
                  setTeamMembers(newTeamMembers)
                }}
                className="border border-gray-10 rounded-md shadow-sm bg-white text-gray-1 w-full text-15px py-10px px-15px mb-3 mr-3"
              />
              <input
                name={`teamEmail${index}`}
                placeholder="Email address"
                type="email"
                required={notifyTeam}
                id={index.toString()}
                value={teamMember.email}
                onChange={e => {
                  const newTeamMembers = [...teamMembers]
                  newTeamMembers[index].email = e.target.value
                  setTeamMembers(newTeamMembers)
                }}
                className="border border-gray-10 rounded-md shadow-sm bg-white text-gray-1 w-full text-15px py-10px px-15px mb-3 ml-3"
              />
              <button
                type="button"
                className={`font-extrabold ml-2 ${
                  teamMembers.length > 1 ? "" : "invisible"
                }`}
                onClick={() => {
                  const newTeamMembers = [...teamMembers]
                  newTeamMembers.splice(index, 1)
                  setTeamMembers(newTeamMembers)
                }}
              >
                X
              </button>
            </div>
          ))}
          <button
            type="button"
            className="mb-8"
            onClick={() => {
              setTeamMembers([...teamMembers, { name: "", email: "" }])
            }}
          >
            Add another person
          </button>
        </div>
        {/* Submit */}
        <div className="flex items-center mb-6">
          <button
            type="submit"
            disabled={state.submitting}
            style={{ flex: 1 }}
            className="py-2 px-4 bg-blue-1 rounded-full text-white text-15px"
          >
            Complete checklist
          </button>
          <span className="text-red ml-3" style={{ flex: 3 }}>
            {submissionError}
          </span>
        </div>
        <span className="text-14px text-gray-1">
          This form allows you to submit a maximum of 10 files. If you have
          more, please upload zip files or send them to us via email.
        </span>
      </form>
    </div>
  )
}

export default IntegrationChecklist
